/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.md-editor-wrap {
  display: flex;
  flex-direction: column;
  background-color: var(-bs-body-bg);
  border: 1px solid var(--an-ced4da);
  overflow: hidden;
  .toolbar-wrap {
    border-bottom: 1px solid var(--an-ced4da);
    .toolbar-divider {
      float: left;
      width: 1px;
      height: 15px;
      background-color: var(--an-toolbar-divider);
      margin: 10px 8px;
    }
    .toolbar-item-wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      margin: 0 2px;
      line-height: 0;
      .dropdown-menu {
        line-height: 1.5;
      }
      &.right {
        float: right;
      }
      .dropdown-toggle {
        &::after {
          display: none;
        }
      }
    }
    .toolbar {
      box-sizing: border-box;
      outline: none;
      cursor: pointer;
      background-color: var(--bs-body-bg);
      height: 100%;
      width: 100%;
      border-radius: 3px;
      font-size: 20px;
      line-height: 20px;
      &:hover {
        background-color: var(--an-editor-toolbar-hover);
      }
      &:focus {
        background-color: var(--ans-editor-toolbar-focus);
      }
    }
    .popup-wrap {
      position: absolute;
      width: 500px;
      margin-right: auto;
      border: 1px solid #cacaca;
      background: #fff;
      z-index: 9999;
      visibility: hidden;

      &.heading-add {
        width: 158px;
        padding: 8px 0;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li {
            padding: 4px 24px;
            cursor: pointer;
            &:hover {
              background-color: #eee;
            }
            h2 {
              font-size: 24px;
              margin: 0;
            }
            h3 {
              font-size: 20px;
              margin: 0;
            }
            h4 {
              font-size: 19px;
              margin: 0;
            }
          }
        }
      }
    }
  }
  .content-wrap {
    height: 264px;
  }

  .CodeMirror {
    height: auto;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
      'Courier New', monospace !important;
    font-size: 14px;
    pre.CodeMirror-line,
    pre.CodeMirror-line-like {
      padding: 0 16px;
    }
    .CodeMirror-lines {
      padding: 16px 0;
    }
    .CodeMirror-placeholder {
      color: var(--an-editor-placeholder-color);
    }
  }
}
.preview-wrap {
  overflow-y: auto;
  min-height: 20px;
  padding: 16px;
  img {
    max-width: 100%;
  }
}
